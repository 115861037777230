import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface SEOProps {
  title: string;
  lang: "nl" | "en";
  pathName: string;
  description?: string;
  image?: string;
  meta?: Array<{ name: string; content: string }>;
}

function SEO({
  lang,
  pathName,
  description,
  image,
  meta = [],
  title,
}: SEOProps) {
  const { site }: any = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription: string = description || site.siteMetadata.description;
  const imageUrl: string = image || site.siteMetadata.image;
  const defaultTitle: string = site.siteMetadata.title || "schuiflade.nl";

  const siteUrl = site.siteMetadata.siteUrl;

  var dutchAlternate = `${siteUrl}${pathName.replace(`${lang}/`, "")}`;
  var englishAlternate = `${siteUrl}/en${pathName}`;

  if (lang === "en") {
    englishAlternate = `${siteUrl}${pathName}`;
  }

  // console.log(`Dutch: ${dutchAlternate}`);
  // console.log(`English: ${englishAlternate}`);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : defaultTitle}
      link={[
        {
          rel: "alternate",
          hrefLang: "nl",
          href: dutchAlternate,
        },
        {
          rel: "alternate",
          hrefLang: "en",
          href: englishAlternate,
        },
        {
          rel: "alternate",
          hrefLang: "x-default",
          href: dutchAlternate,
        },
        {
          rel: "canonical",
          href: lang === "en" ? englishAlternate : dutchAlternate,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

export default SEO;
